// User Login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";

// USer List
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILURE = "USER_CREATE_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_ROLE_LIST_REQUEST = "USER_ROLE_LIST_REQUEST";
export const USER_ROLE_LIST_SUCCESS = "USER_ROLE_LIST_SUCCESS";
export const USER_ROLE_LIST_FAILURE = "USER_ROLE_LIST_FAILURE";

export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_FAILURE = "ALL_USER_LIST_FAILURE";
// Cost Center
export const CREATE_COST_CENTER_REQUEST = "CREATE_COST_CENTER_REQUEST";
export const CREATE_COST_CENTER_SUCCESS = "CREATE_COST_CENTER_SUCCESS";
export const CREATE_COST_CENTER_FAILURE = "CREATE_COST_CENTER_FAILURE";

export const COST_CENTER_LIST_REQUEST = "COST_CENTER_LIST_REQUEST";
export const COST_CENTER_LIST_SUCCESS = "COST_CENTER_LIST_SUCCESS";
export const COST_CENTER_LIST_FAILURE = "COST_CENTER_LIST_FAILURE";

//Product Management
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const ALL_PRODUCT_LIST_REQUEST = "ALL_PRODUCT_LIST_REQUEST";
export const ALL_PRODUCT_LIST_SUCCESS = "ALL_PRODUCT_LIST_SUCCESS";
export const ALL_PRODUCT_LIST_FAILURE = "ALL_PRODUCT_LIST_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const IMPORT_CSV_PRODUCT_REQUEST = "IMPORT_CSV_PRODUCT_REQUEST";
export const IMPORT_CSV_PRODUCT_SUCCESS = "IMPORT_CSV_PRODUCT_SUCCESS";
export const IMPORT_CSV_PRODUCT_FAILURE = "IMPORT_CSV_PRODUCT_FAILURE";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILURE = "PRODUCT_DELETE_FAILURE";

export const SCANNED_PRODUCT_LIST_REQUEST = "SCANNED_PRODUCT_LIST_REQUEST";
export const SCANNED_PRODUCT_LIST_SUCCESS = "SCANNED_PRODUCT_LIST_SUCCESS";
export const SCANNED_PRODUCT_LIST_FAILURE = "SCANNED_PRODUCT_LIST_FAILURE";

export const ALL_SCANNED_PRODUCT_LIST_REQUEST =
  "ALL_SCANNED_PRODUCT_LIST_REQUEST";
export const ALL_SCANNED_PRODUCT_LIST_SUCCESS =
  "ALL_SCANNED_PRODUCT_LIST_SUCCESS";
export const ALL_SCANNED_PRODUCT_LIST_FAILURE =
  "ALL_SCANNED_PRODUCT_LIST_FAILURE";

export const DELETE_SCANNED_PRODUCT_REQUEST = "DELETE_SCANNED_PRODUCT_REQUEST";
export const DELETE_SCANNED_PRODUCT_SUCCESS = "DELETE_SCANNED_PRODUCT_SUCCESS";
export const DELETE_SCANNED_PRODUCT_FAILURE = "DELETE_SCANNED_PRODUCT_FAILURE";

export const DELETE_ALL_SCANNED_PRODUCT_REQUEST =
  "DELETE_ALL_SCANNED_PRODUCT_REQUEST";
export const DELETE_ALL_SCANNED_PRODUCT_SUCCESS =
  "DELETE_ALL_SCANNED_PRODUCT_SUCCESS";
export const DELETE_ALL_SCANNED_PRODUCT_FAILURE =
  "DELETE_ALL_SCANNED_PRODUCT_FAILURE";

export const SEARCH_SCANNED_PRODUCT_LIST_REQUEST =
  "SEARCH_SCANNED_PRODUCT_LIST_REQUEST";
export const SEARCH_SCANNED_PRODUCT_LIST_SUCCESS =
  "SEARCH_SCANNED_PRODUCT_LIST_SUCCESS";
export const SEARCH_SCANNED_PRODUCT_LIST_FAILURE =
  "SEARCH_SCANNED_PRODUCT_LIST_FAILURE";

export const EXPORT_FILE_REQUEST = "EXPORT_FILE_REQUEST";
export const EXPORT_FILE_SUCCESS = "EXPORT_FILE_SUCCESS";
export const EXPORT_FILE_FAILURE = "EXPORT_FILE_FAILURE";

export const EXPORT_FILE_STATUS_REQUEST = "EXPORT_FILE_STATUS_REQUEST";
export const EXPORT_FILE_STATUS_SUCCESS = "EXPORT_FILE_STATUS_SUCCESS";
export const EXPORT_FILE_STATUS_FAILURE = "EXPORT_FILE_STATUS_FAILURE";

// Email Management

export const EMAIL_LIST_REQUEST = "EMAIL_LIST_REQUEST";
export const EMAIL_LIST_SUCCESS = "EMAIL_LIST_SUCCESS";
export const EMAIL_LIST_FAILURE = "EMAIL_LIST_FAILURE";

export const CREATE_EMAIL_REQUEST = "CREATE_EMAIL_REQUEST";
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS";
export const CREATE_EMAIL_FAILURE = "CREATE_EMAIL_FAILURE";

export const DELETE_EMAIL_REQUEST = "DELETE_EMAIL_REQUEST";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const DELETE_EMAIL_FAILURE = "DELETE_EMAIL_FAILURE";

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";

export const SEARCH_EMAIL_LIST_REQUEST = "SEARCH_EMAIL_LIST_REQUEST";
export const SEARCH_EMAIL_LIST_SUCCESS = "SEARCH_EMAIL_LIST_SUCCESS";
export const SEARCH_EMAIL_LIST_FAILURE = "SEARCH_EMAIL_LIST_FAILURE";

// Dashboard

export const DASHBOARD_CALCULATION_REQUEST = "DASHBOARD_CALCULATION_REQUEST";
export const DASHBOARD_CALCULATION_SUCCESS = "DASHBOARD_CALCULATION_SUCCESS";
export const DASHBOARD_CALCULATION_FAILURE = "DASHBOARD_CALCULATION_FAILURE";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";
