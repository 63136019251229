import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import {
  CREATE_COST_CENTER_REQUEST,
  CREATE_COST_CENTER_SUCCESS,
  CREATE_COST_CENTER_FAILURE,
  COST_CENTER_LIST_REQUEST,
  COST_CENTER_LIST_SUCCESS,
  COST_CENTER_LIST_FAILURE,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  SCANNED_PRODUCT_LIST_REQUEST,
  SCANNED_PRODUCT_LIST_SUCCESS,
  SCANNED_PRODUCT_LIST_FAILURE,
  IMPORT_CSV_PRODUCT_REQUEST,
  IMPORT_CSV_PRODUCT_SUCCESS,
  IMPORT_CSV_PRODUCT_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  ALL_PRODUCT_LIST_REQUEST,
  ALL_PRODUCT_LIST_SUCCESS,
  ALL_PRODUCT_LIST_FAILURE,
  ALL_SCANNED_PRODUCT_LIST_REQUEST,
  ALL_SCANNED_PRODUCT_LIST_SUCCESS,
  ALL_SCANNED_PRODUCT_LIST_FAILURE,
  DELETE_SCANNED_PRODUCT_REQUEST,
  DELETE_SCANNED_PRODUCT_SUCCESS,
  DELETE_SCANNED_PRODUCT_FAILURE,
  DELETE_ALL_SCANNED_PRODUCT_REQUEST,
  DELETE_ALL_SCANNED_PRODUCT_SUCCESS,
  DELETE_ALL_SCANNED_PRODUCT_FAILURE,
  SEARCH_SCANNED_PRODUCT_LIST_REQUEST,
  SEARCH_SCANNED_PRODUCT_LIST_SUCCESS,
  SEARCH_SCANNED_PRODUCT_LIST_FAILURE,
  EXPORT_FILE_REQUEST,
  EXPORT_FILE_SUCCESS,
  EXPORT_FILE_FAILURE,
  EXPORT_FILE_STATUS_REQUEST,
  EXPORT_FILE_STATUS_SUCCESS,
  EXPORT_FILE_STATUS_FAILURE,
} from "../Type";

export const createCostCenter = (costCenter) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_COST_CENTER_REQUEST,
    });
    const body = {
      name: costCenter,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/cost_center/create/`,
      body,
      config
    );

    dispatch({
      type: CREATE_COST_CENTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_COST_CENTER_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCostCenterList = () => async (dispatch) => {
  try {
    dispatch({
      type: COST_CENTER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/cost_center/list/`,
      config
    );

    dispatch({
      type: COST_CENTER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COST_CENTER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProductList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/product/ns_list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProductAction =
  (inventoryNumber, assetId, subNumber, costCenter, name, department) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PRODUCT_REQUEST,
      });
      const body =
        inventoryNumber === ""
          ? {
              //inventory_number: inventoryNumber,
              asset: assetId,
              sub_number: subNumber,
              cost_center: costCenter,
              name: name,
              department: department,
            }
          : {
              inventory_number: inventoryNumber,
              asset: assetId,
              sub_number: subNumber,
              cost_center: costCenter,
              name: name,
              department: department,
            };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/product/create/`,
        body,
        config
      );

      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CREATE_PRODUCT_FAILURE,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const importCsvProductAction = (body) => async (dispatch) => {
  try {
    dispatch({
      type: IMPORT_CSV_PRODUCT_REQUEST,
    });
    //const body =
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/product/create/`,
      body,
      config
    );

    dispatch({
      type: IMPORT_CSV_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMPORT_CSV_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getScannedProductList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: SCANNED_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/product/list/?client=web&limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: SCANNED_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SCANNED_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteProductAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/product/delete/${id}/`,
      config
    );
    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAllProductList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/product/ns_list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: ALL_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAllScannedProductList = (limit) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_SCANNED_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/product/list/?client=web&limit=${limit}&offset=0`,
      config
    );

    dispatch({
      type: ALL_SCANNED_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_SCANNED_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const uploadProductCsvAction = (uploadData) => async (dispatch) => {
  try {
    dispatch({
      type: IMPORT_CSV_PRODUCT_REQUEST,
    });
    const body = uploadData;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/product/create/`,
      body,
      config
    );

    dispatch({
      type: IMPORT_CSV_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMPORT_CSV_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteScannedProductAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SCANNED_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //"Content-Type": "application/json",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/product/delete_sp/${id}/`,
      config
    );
    dispatch({
      type: DELETE_SCANNED_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SCANNED_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteAllScannedProductAction = (allId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ALL_SCANNED_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      data: {
        ids: allId,
      },
    };
    // const body = {
    //   ids: allId,
    // };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/product/delete_sp/`,
      config
      //body
    );
    dispatch({
      type: DELETE_ALL_SCANNED_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ALL_SCANNED_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchScannedProductList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_SCANNED_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/product/list/?client=web&keyword=${keyword}&limit=50&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_SCANNED_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SCANNED_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const exportFileAction = (body) => async (dispatch) => {
  try {
    dispatch({
      type: EXPORT_FILE_REQUEST,
    });
    //const body =
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/export/export-data/`,
      body,
      config
    );

    dispatch({
      type: EXPORT_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_FILE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getExportFileStatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EXPORT_FILE_STATUS_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const instance = axios.create();

    instance.interceptors.response.use(
      (response) => {
        if (
          response.data &&
          response.data.status &&
          (response.data.status === "processing" ||
            response.data.status === "pending")
        ) {
          setTimeout(() => {
            dispatch(getExportFileStatus(id));
          }, 5000);
        }
        return response;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    const data = await instance.get(
      `${process.env.REACT_APP_BASE_URL}/api/export/${id}/`,
      config
    );

    dispatch({
      type: EXPORT_FILE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_FILE_STATUS_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
