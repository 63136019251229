import { toast } from "react-toastify";

export const errorToast = (message, time) => {
  toast.error(message, {
    position: "top-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
