import { createStore, applyMiddleware, combineReducers } from "redux";
import {
  userLoginReducers,
  userListReducers,
  createUserReducer,
  deleteUserReducer,
  updateUserReducer,
  userRoleListReducers,
  allUserListReducers,
} from "./reducers/UserReducer";
import {
  createCostCenterReducer,
  getCostCenterListReducers,
  getProductListReducers,
  createProductReducer,
  getScannedProductListReducers,
  imporCsvProductReducer,
  deleteProductReducer,
  getAllProductListReducers,
  getAllScannedProductListReducers,
  uploadProductCSVReducer,
  deleteScannedProductReducer,
  deleteAllScannedProductReducer,
  searchScannedListReducer,
  requestFileExportReducer,
  exportFileStatusReducer,
} from "./reducers/InventoryReducer";
import {
  emailListReducers,
  createEmailReducer,
  deleteEmailReducer,
  updateEmailReducer,
} from "./reducers/EmailReducer";
import {
  getDashboardCalculationReducers,
  getUserProfileReducers,
} from "./reducers/DashboardReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const reducer = combineReducers({
  userLogin: userLoginReducers,
  userListDetails: userListReducers,
  getAllUserList: allUserListReducers,
  createUser: createUserReducer,
  deleteUser: deleteUserReducer,
  updateUser: updateUserReducer,
  userRoleList: userRoleListReducers,
  createCostCenter: createCostCenterReducer,
  getCostCenterList: getCostCenterListReducers,
  getProductList: getProductListReducers,
  getAllProductList: getAllProductListReducers,
  createProduct: createProductReducer,
  importProductCsv: imporCsvProductReducer,
  uploadProductCsv: uploadProductCSVReducer,
  getScannedProducts: getScannedProductListReducers,
  getAllScannedProducts: getAllScannedProductListReducers,
  deleteProduct: deleteProductReducer,
  getEmailList: emailListReducers,
  createEmail: createEmailReducer,
  deleteEmail: deleteEmailReducer,
  updateEmail: updateEmailReducer,
  dashboardCalculation: getDashboardCalculationReducers,
  userProfile: getUserProfileReducers,
  deleteScanProduct: deleteScannedProductReducer,
  deleteAllScanProduct: deleteAllScannedProductReducer,
  searchScannedList: searchScannedListReducer,
  requestFileExport: requestFileExportReducer,
  exportFileStatus: exportFileStatusReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
